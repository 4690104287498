import React, { useReducer, useContext, useEffect } from 'react'
/** @jsx jsx */
import Amplify from "@aws-amplify/core";
import Analytics from '@aws-amplify/analytics';
import { API, graphqlOperation, Cache } from "aws-amplify"
import { ListAllAssets, ListShotAssetsDeltasIndex, ListShotAssetsDateDeltasIndex } from "../graphql/shotassetQueries"

import Helmet from 'react-helmet'

import {loadStripe} from '@stripe/stripe-js';
// import {
//   CardElement,
//   Elements,
//   useStripe,
//   useElements,
// } from '@stripe/react-stripe-js';

import { Flex, Box, jsx } from 'theme-ui'
import { Link } from 'gatsby'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'
import Spinner from 'react-bootstrap/Spinner'
import ListGroup from 'react-bootstrap/ListGroup'
import ProductCard from '../@geocine/gatsby-theme-organization/components/ProductCard'
import Layout from '../@geocine/gatsby-theme-organization/components/Layout'
import Card from 'react-bootstrap/Card'
import Accordion from 'react-bootstrap/Accordion'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight, faAngleLeft, faSpinner} from "@fortawesome/free-solid-svg-icons";

// import Layout from "../components/layout"
// import SEO from "../components/seo"
import awsconfig from "../aws-exports";

import 'src/css/theme.scss'
import favicon from '../@geocine/gatsby-theme-organization/assets/favicon.ico'
import '@fortawesome/fontawesome-svg-core/styles.css'
import { config } from '@fortawesome/fontawesome-svg-core'

import { AppsContext } from '../AppsProvider'

// Prevent fontawesome from dynamically adding its css since we did it manually above
config.autoAddCss = false



// http://d1w2buicv2uw3m.cloudfront.net/V2/6B3E1A00BDAB94E4DA166A30A759D44C/assets/9519550.jpg
Amplify.configure(awsconfig); // Configure Amplify

const colorWhite50 = "rgba(255,255,255, .5)"
const colorBlack50 = "rgba(0,0,0, .5)"
const colorBlack80 = "rgba(0,0,0, .8)"
const colorWhiteHover = "rgba(255,255,255, .7)"
const colorSubLinkBackground = "rgba(0,0,0, .1)"

const linkStyle = {
  color: 'black',
	float: 'left',
	padding: '8px 16px',
	textDecoration: 'none',
	transition: 'backgroundColor .3s',
	':hover': '',
}


// const faqHeader =   {
//         fontSize: '27px,'
//         margin: '20px'
//     }

 // const panelHeading =  [data-toggle="collapse"]:after {
 //        font-family: 'Glyphicons Halflings';
 //        content: "e072"; /* "play" icon */
 //        float: right;
 //        color: #F58723;
 //        font-size: 18px;
 //        line-height: 22px;
 //        /* rotate "play" icon from > (right arrow) to down arrow */
 //        -webkit-transform: rotate(-90deg);
 //        -moz-transform: rotate(-90deg);
 //        -ms-transform: rotate(-90deg);
 //        -o-transform: rotate(-90deg);
 //        transform: rotate(-90deg);
 //    }

 //    .panel-heading [data-toggle="collapse"].collapsed:after {
 //        /* rotate "play" icon from > (right arrow) to ^ (up arrow) */
 //        -webkit-transform: rotate(90deg);
 //        -moz-transform: rotate(90deg);
 //        -ms-transform: rotate(90deg);
 //        -o-transform: rotate(90deg);
 //        transform: rotate(90deg);
 //        color: #454444;
 //    }



var stripePromise, checkoutSessionId, uGroups

// const CheckoutForm = () => {
//   const stripe = useStripe();
//   const elements = useElements();

//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     const {error, paymentMethod} = await stripe.createPaymentMethod({
//       type: 'card',
//       card: elements.getElement(CardElement),
//     });
//   };

//   return (
//     <form onSubmit={handleSubmit}>
//       <CardElement />
//       <button type="submit" disabled={!stripe}>
//         Pay
//       </button>
//     </form>
//   );
// };






// var createCheckoutSession = function(isBuyingSticker) {

//   let apiName = 'shotwireAPI'; // replace this with your api name.
//   let path = '/create-checkout-session'; //replace this with the path you have configured on your API
//   let myInit = {
//       body: JSON.stringify({ isBuyingSticker }),//{}, // replace this with attributes you need
//       headers: {"Content-Type": "application/json"} // OPTIONAL
//   }

//   API.post(apiName, path, myInit).then(response => {
//       // Add your code here
//       console.log('response:',response)
//       return response.json();
//   }).then(data => {
//       // Add your code here
//       console.log('data:',data)
//       checkoutSessionId = data.checkoutSessionId;
//   }).catch(error => {
//       console.log(error.response)
//   });

//         fetch("/create-checkout-session", {
//             method: "POST",
//             headers: {
//               "Content-Type": "application/json"
//             },
//             body: JSON.stringify({ isBuyingSticker })
//           })
//             .then(function(result) {
//             return result.json();
//           })
//           .then(function(data) {
//             checkoutSessionId = data.checkoutSessionId;
//           });
//   };

const HelpPage = () => {
  // let stripe = null 
  let session = null

  const appsContext = React.useContext(AppsContext)

  // const stripeInit = async () => {
  //   stripe = await loadStripe('pk_test_8iiUqRzMdP7ipyKMn1wHDhyF');
  //   const stripePromise = loadStripe('pk_test_JJ1eMdKN0Hp4UFJ6kWXWO4ix00jtXzq5XG');
  //   console.log('stripe:', stripe)
  //   appsContext.dispatch({ type: "STRIPE", stripe })
    
  
  // }

  // const stripeSession = async (planId) => {
  //   console.log('stripePromise...', stripePromise)
  //   session = await stripePromise.checkout.sessions.create({
  //     // customer_email: 'customer@example.com',
  //     payment_method_types: ['card'],
  //     subscription_data: {
  //       items: [{
  //         plan: planId,
  //       }],
  //       trial_from_plan: true,
  //     },
  //     success_url: 'https://video2020.shotwire.com/buysccss',
  //     cancel_url: 'https://video2020.shotwire.com/buycncl',
  //   });

  //   return session
    
  // }

  // (async () => {
  //   const session = await stripe.checkout.sessions.create({
  //     customer_email: 'customer@example.com',
  //     payment_method_types: ['card'],
  //     subscription_data: {
  //       items: [{
  //         plan: 'plan_123',
  //       }],
  //     },
  //     success_url: 'https://example.com/success',
  //     cancel_url: 'https://example.com/cancel',
  //   });
  // })();

  const handleBuy = (e, planId) => {
    if (e){ 
        e.preventDefault();
      
        // console.log('stripe checkout...')
        // const sessId = stripeSession(planId)
        // console.log('stripe sessId', sessId)
        appsContext.dispatch({type:"CHECKOUT",isCheckoutLoading:true})

        Analytics.record({
            name: 'checkout', 
            // Attribute values must be strings
            attributes: { product: "cloud video", plan: planId }
        });

        createCheckoutSession(planId).then(sessId => {
            // Add your code here
            // console.log('sessId:',sessId)
            // checkoutSessionId = sessId;
            appsContext.dispatch({type:"CHECKOUT", isCheckoutLoading:false})


        })
        
        // console.log('checkoutSessionId', checkoutSessionId)
        // Initiate payment
        // stripePromise
        //   .redirectToCheckout({
        //     sessionId: checkoutSessionId
        //   })
        //   .then(function(result) {
        //     console.log('stripe result:', result)
        //     // console.log("error");
        //     // If `redirectToCheckout` fails due to a browser or network
        //     // error, display the localized error message to your customer
        //     // using `result.error.message`.
        //   })
        //   .catch(function(err) {
        //     console.log('stripe err:',err);
        //   });

        // stripePromise.redirectToCheckout({
        //     // Make the id field from the Checkout Session creation API response
        //     // available to this file, so you can provide it as parameter here
        //     // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
        //     sessionId: sessId
        //   }).then(function (result) {
        //     console.log('stripe result:', result)
        //     // If `redirectToCheckout` fails due to a browser or network
        //     // error, display the localized error message to your customer
        //     // using `result.error.message`.
        //   });

      

    }
  }
  
  async function createCheckoutSession(planId) { 
    let apiName = 'shotwireAPI'; // replace this with your api name.
    let path = '/create-checkout-session'; //replace this with the path you have configured on your API
    let myInit = {
                    body: {
                      planId: planId
                    }, // replace this with attributes you need
                    headers: {} // OPTIONAL e.g. "Content-Type": "application/json"
                 }

    await API.post(apiName, path, myInit).then(response => {
      // Add your code here
      // console.log('response:',response)
      return response.checkoutSessionId;
    }).then(sessId => {
        // Add your code here
        // console.log('sessId:',sessId)
        checkoutSessionId = sessId;

        stripePromise
          .redirectToCheckout({
            sessionId: sessId
          })
          .then(function(result) {
            // console.log('stripe result:', result)
            if(result.error){
              console.log('error:', result.error.message)
              appsContext.dispatch({ type:"TOASTS", toasts:{ show:true, display:'block', displayTopPosition: '1500px', title:"Purchase Error", body: result.error.message }})
            }
            // console.log("error");
            // If `redirectToCheckout` fails due to a browser or network
            // error, display the localized error message to your customer
            // using `result.error.message`.
          })
          .catch(function(err) {
            // console.log('stripe err:',err);
          });


    }).catch(error => {
        // console.log('sess error:',error)
    });
    // return sess
}

    useEffect(() => {
      // console.log('get for user product and stats...')
      let isMounted = true
      if(isMounted === true){
        // console.log('setting up stripe element...')
          // setupElements()

          if(appsContext.state.user){
            const  userInfo  = appsContext.state.user
            // console.log('userInfo: ', userInfo)

            if(typeof userInfo.signInUserSession.idToken.payload[`cognito:groups`] != 'undefined'){
              uGroups = userInfo.signInUserSession.idToken.payload[`cognito:groups`]
              // console.log('uGroups:',uGroups)
              // console.log('uRoles: ', uRoles)
              if( uGroups.some(r => ['Admins'].indexOf(r) >= 0) ){
                // console.log('Admin user.')
                 appsContext.dispatch({ type: "ISADMIN", isAdmin:true }) 
               }
              if( uGroups.some(r => ['Uploaders'].indexOf(r) >= 0) ){
                // console.log('Uploader user.')
                 appsContext.dispatch({ type: "ISUPLOADER", isUploader:true }) 
               }
              // console.log('isAdmin:', isAdmin)
            }

         
          }
        }

        return () => {
                  //console.log('state2: ', state)
                  // subscription.unsubscribe();
                  isMounted = false
                  
                };

  },[ ])

	useEffect(() => {
		      try{
            let isMounted = true
            // if(isMounted === true) setupElements();
           
             if(appsContext.state.user){
              // const  userInfo  = appsContext.state.user
              // console.log('userInfo: ', userInfo)
              // const uRoles = userInfo.attributes[`custom:roles`].split(',')
              //user roles checks
              // if( uRoles.some(r => ['videoAdmin','superAdmin'].indexOf(r) >= 0) ) appsContext.dispatch({ type: "ISADMIN", isAdmin:true })  // checks for one or more permissions to allow access to specific components
             }

          
          // createCheckoutSession();
          // stripeInit()
         // console.log('appsContext.state.currentCategory:', appsContext.state.currentCategory)
              // console.log('appsContext.state.categoryFilter:', appsContext.state.categoryFilter)
              // console.log('appsContext.state.categoryMetaField:', appsContext.state.categoryMetaField)
              // getCachedAssetData()
              if( appsContext.state.isAdmin ){
                // console.log('live data...')
                // getAssetSearchData()
              }
              // getData();
              
              // const subscription = API.graphql(graphqlOperation(onCreateAlbum)).subscribe({
               //    next: eventData => {
               //      const album = eventData.value.data.onCreateAlbum;
               //      dispatch({ type: "SUBSCRIPTION", album });
               //    }
               //  });
                return () => {
                  //console.log('state2: ', state)
                  // subscription.unsubscribe();
                  isMounted = false
                  
                };
          } catch(error) {
            // console.log('useEffect error:',error)
          }
		}, [ ]);




  //cost from $.01@SD - $.06@4k / min of converted footage depending on resolution and total fps processed.
 


  return(
  	
<Layout>
  <Helmet>
    <title>Shotwire Frequently Asked Questions</title>
    <link rel="icon" href={favicon} type="image/x-icon" />
    
  </Helmet>

 <Container>
 <Row>
 <div sx={{
  paddingLeft: '10px',
  paddingTop: '20px'
 }}>
 <h1>Frequently Asked Questions. (FAQ's)</h1>
 <h5>Shotwire Product & Services Info</h5>
 
 </div>
 </Row>
 <Row>
 <Col sx={{
  // paddingLeft: '10px',
  // padding: '20px 0px 20px',
  marginTop: '20px',
  marginBottom: '20px',
  fontSize: '.9rem'

 }}>
 
  <p>
  This section contains information related to <strong>Shotwire</strong> and its products. If you cannot find an answer to your question, make sure to contact us. 
</p>
 </Col>
 </Row>

<Row>
<Col sm="12" lg="12">
    <h3>General questions</h3>

    <Accordion>
      <Card>
        <Card.Header>
          <Accordion.Toggle as={Button} variant="link" eventKey="0">
            <h4>Is account registration required?</h4>
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <Card.Body sx={{color:colorBlack80}}>Account registration at <strong>Shotwire</strong> is only required if you will be selling or buying media content or purchasing Shotwire products or services. 
                     </Card.Body>
        </Accordion.Collapse>
      </Card>
      {
        // <Card>
        //       <Card.Header>
        //         <Accordion.Toggle as={Button} variant="link" eventKey="1">
        //           <h4>Can I submit my own videos or photos?</h4>
        //         </Accordion.Toggle>
        //       </Card.Header>
        //       <Accordion.Collapse eventKey="1">
        //         <Card.Body sx={{color:colorBlack80}}>A lot of the content of the site has been submitted by the community. Whether it is a commercial video or photo 
        //                   or a free one, you are encouraged to contribute. All credits are published along with the resources. </Card.Body>
        //       </Accordion.Collapse>
        //     </Card>
          }
      <Card>
        <Card.Header>
          <Accordion.Toggle as={Button} variant="link" eventKey="2">
            <h4>Can I cancel my account anytime?</h4>
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="2">
          <Card.Body sx={{color:colorBlack80}}>Yes, you can cancel your account anytime in your account settings. Services or content licenses already puchased are not refundable.</Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
    <br />
</Col>
<Col sm="12" lg="12">
<h3>Sellers</h3>
<Accordion>
  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="0">
        <h4>Who can sell videos or photos?</h4>
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="0">
      <Card.Body sx={{color:colorBlack80}}>Any registed user, who represents a work as a copyright owner or agent of the copyright owner , can list videos or photos for licensing in the <strong>Shotwire</strong> private or public marketplaces.</Card.Body>
    </Accordion.Collapse>
  </Card>
  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="1">
        <h4>What is the currency used for all transactions?</h4>
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="1">
      <Card.Body sx={{color:colorBlack80}}>All prices for videos, photo and other services, including each seller's or buyer's account balance are in <strong>USD</strong></Card.Body>
    </Accordion.Collapse>
  </Card>
  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="2">
        <h4>I want to sell my videos or photos - what are the steps?</h4>
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="2">
      <Card.Body sx={{color:colorBlack80}}><div className="card-block">
                    The steps involved in this process are really simple. All you need to do is:
                    <ul>
                        <li>Register an account</li>
                        <li>Activate your account</li>
                        <li>Use the <strong>Upload Button</strong> for videos or photos in your account control center section and upload your video or photo</li>
                        <li>If you want your content listing in the Shotwire public marketplace, the next step is the approval step, which usually takes about 72 hours. If you are listing your content for sale on your own private pages, no approval is required.</li>
                    </ul>
                </div>
                </Card.Body>
    </Accordion.Collapse>
  </Card>
  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="3">
        <h4>How much do I get from each sale?</h4>
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="3">
      <Card.Body sx={{color:colorBlack80}}><strong>Shotwire</strong> offers a great, 92% payout rate for each seller, regardless of any restrictions, such as volume, date of entry, etc.
</Card.Body>
    </Accordion.Collapse>
  </Card>
  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="4">
        <h4>Why sell my videos or photos here?</h4>
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="4">
      <Card.Body sx={{color:colorBlack80}}>There are a number of reasons why you should join us:
                    <ul>
                        <li>A great 92% flat rate for your content.</li>
                        <li>For the public marketplace we offer fast response and approval times. It typically takes up to 72 hours for a contributor to get reviewed at other services.</li>
                        <li>We are not an exclusive marketplace. This means that you can sell your content on <strong>Shotwire</strong>, as well as on any other marketplace, and thus increase your earning potential.</li>
                    </ul></Card.Body>
    </Accordion.Collapse>
  </Card>




</Accordion>
<br />
</Col>
<Col sm="12" lg="12">
<h3>Buyers</h3>
<Accordion>
  
  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="0">
        <h4>I want to buy a license for a video or photo - what are the steps?</h4>
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="0">
      <Card.Body sx={{color:colorBlack80}}>Buying a content license on <strong>Shotwire</strong> is really simple. 
                    Each video or photo has a preview. <br />
                    Once you have selected a video or photo, you can quickly and securely pay with a major credit card or with any of today's international crypto currencies (e.g. Bitcoin, Ether, LTC). Payments are securely processed by Stripe for credit cards and Coinbase for international crypto currencies.
                    <br /> 
                    <br />
                    Just add videos or photos to your cart and checkout with the pay button options to begin the payment process.
                    <br />
                    Once the transaction is complete, you gain full resolution access to the purchased video or photo and the rights to use the content your project. 
                    <br />
                    <br />
                    Looking for content license packages? You can find subscriptions for content licenses
                    <Link to="/VideoSubscriptions" 
      // style={ footerLinkStyle } 
      activeStyle={{ color: `#FFF`, textDecoration: `underline` }}
      > here </Link>
                    </Card.Body>
    </Accordion.Collapse>
  </Card>
  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="1">
        <h4>Search returns no results, how do I get the content I need?</h4>
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="1">
      <Card.Body sx={{color:colorBlack80}}>Please be sure that you have an account and that you are logged in. Search results for public users may not include private content visible only to authenticated Shotwire users with specific access rights. Some content is restricted by content creators.<br /> 
                    <br />
                    If you are logged in and still don't see the content you need, please contact us. We will search our offline archives, our content contributors and partners and send an alert to you with the content you requested, if found. Make your request at sales@shotwire.com
                    <br />
                    </Card.Body>
    </Accordion.Collapse>
  </Card>
  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="2">
        <h4>Do you sell software products, services, or consulting?</h4>
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="2">
      <Card.Body sx={{color:colorBlack80}}>You may purchase <strong>Shotwire</strong> cloud and enterprise services using the links below:<br />  
      <Link to="/Cloud" 
      // style={ footerLinkStyle } 
      activeStyle={{ color: `#FFF`, textDecoration: `underline` }}
      >Shotwire Cloud Products
      </Link>
      <br />
      <Link to="/Enterprise" 
      // style={ footerLinkStyle } 
      activeStyle={{ color: `#FFF`, textDecoration: `underline` }}
      >Shotwire Enterprise Products</Link>
      <br /><br />
      For software consulting information or to discuss your project, contact <strong>Shotwire</strong> at sales@shotwire.com<br />
      You may also reach us by phone: +1(424)229-2118
      
      </Card.Body>
    </Accordion.Collapse>
  </Card>


</Accordion>
</Col>
</Row>
      </Container>
      
    <br />
    <br />
    <br />

    



 </Layout>
 )
}


// <Layout>
//     <SEO title="Celebrity Candids" />
//     <h1>Hi from the second page</h1>
//     <p>Celebrity Candids</p>
//     <Link to="/">Go back to the homepage</Link>
//   </Layout>

export default HelpPage